<template>

  <section id="works" class="works clearfix">
    <div class="container">
      <div class="row">

        <div class="sec-title text-center mb50">
          <h2>服务</h2>
          <div class="devider"><i class="fa fa-heart-o fa-lg"></i></div>
        </div>

        <div class="work-filter wow fadeInRight animated" data-wow-duration="500ms">
          <ul class="text-center">
            <li><a href="javascript:;" data-filter="all" class="active filter">全部</a></li>
            <li><a href="javascript:;" data-filter=".company" class="filter">企服平台</a></li>
            <li><a href="javascript:;" data-filter=".personal" class="filter">私雇平台</a></li>
            <li><a href="javascript:;" data-filter=".sales" class="filter">特聘销售</a></li>
            <li><a href="javascript:;" data-filter=".home" class="filter">高维家园</a></li>
          </ul>
        </div>
      </div>
    </div>

    <div class="project-wrapper">
      <figure class="mix work-item" :class="item.cls" v-for="(item,index) in list" :key="index">
        <img :src="item.img" alt="">
        <figcaption class="overlay">
          <a class="fancybox" rel="works" title="Write Your Image Caption Here" :href="item.img"><i
              class="fa fa-eye fa-lg"></i></a>
          <h4>{{ item.desc }}</h4>
          <p>Photography</p>
        </figcaption>
      </figure>
    </div>
  </section>
</template>
<script>
const prefix = '/static/img/works';

export default {
  name: "Works",
  data() {
    return {
      "list": [
        {
          "cls": "company",
          "img": `${prefix}/item-1.png`,
          "desc": "Labore et dolore magnam",
        },
        {
          "cls": "company",
          "img": `${prefix}/item-2.png`,
          "desc": "Labore et dolore magnam",
        },
        {
          "cls": "company",
          "img": `${prefix}/item-3.png`,
          "desc": "Labore et dolore magnam",
        },
        {
          "cls": "company",
          "img": `${prefix}/item-4.png`,
          "desc": "Labore et dolore magnam",
        },
        {
          "cls": "personal",
          "img": `${prefix}/item-5.png`,
          "desc": "Labore et dolore magnam",
        },
        {
          "cls": "personal",
          "img": `${prefix}/item-6.png`,
          "desc": "Labore et dolore magnam",
        },
        {
          "cls": "sales",
          "img": `${prefix}/item-7.png`,
          "desc": "Labore et dolore magnam",
        },
        {
          "cls": "sales",
          "img": `${prefix}/item-8.png`,
          "desc": "Labore et dolore magnam",
        },
        {
          "cls": "home",
          "img": `${prefix}/item-9.png`,
          "desc": "Labore et dolore magnam",
        },
        {
          "cls": "home",
          "img": `${prefix}/item-10.png`,
          "desc": "Labore et dolore magnam",
        },
        {
          "cls": "home",
          "img": `${prefix}/item-11.png`,
          "desc": "Labore et dolore magnam",
        },
        {
          "cls": "home",
          "img": `${prefix}/item-12.png`,
          "desc": "Labore et dolore magnam",
        }]
    }
  }
}
</script>

<style scoped>

</style>
