<template>
  <section id="team" class="team">
    <div class="container">
      <div class="row">

        <div class="sec-title text-center wow fadeInUp animated mb50" data-wow-duration="700ms">
          <h2>我们的团队</h2>
          <div class="devider"><i class="fa fa-heart-o fa-lg"></i></div>
        </div>

        <!-- single member -->
        <figure class="team-member col-md-3 col-sm-6 col-xs-12 text-center wow fadeInUp animated"
                data-wow-duration="500ms" data-wow-delay="900ms" v-for="(item,index) in list" :key="index">
          <div class="member-thumb">
            <img :src="item.img" alt="Team Member" class="img-responsive">
            <figcaption class="overlay">
              <h5>{{item.desc}}</h5>
              <ul class="social-links text-center">
                <li><a href=""><i class="fa fa-twitter fa-lg"></i></a></li>
                <li><a href=""><i class="fa fa-facebook fa-lg"></i></a></li>
                <li><a href=""><i class="fa fa-google-plus fa-lg"></i></a></li>
              </ul>
            </figcaption>
          </div>
          <h4>{{item.name}}</h4>
          <span>{{item.position}}</span>
        </figure>
        <!-- end single member -->

      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Team",
  data() {
    return {
      list: [
        {img: '/static/img/team/ly.jpeg', name: 'Li Yu', position: '合伙人', desc: '为您服务'},
        {img: '/static/img/team/fch.jpeg', name: 'Fu Can Hui', position: '合伙人', desc: '为您服务'},
        {img: '/static/img/team/ll.jpeg', name: 'Lin Li', position: '合伙人', desc: '为您服务'},
        {img: '/static/img/team/jzj.jpeg', name: 'Jiang Zuo Jia', position: '合伙人', desc: '为您服务'},
      ]
    }
  }
}
</script>

<style scoped>

</style>
