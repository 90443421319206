<template>
  <section id="slider">
    <div id="carousel-example-generic" class="carousel slide" data-ride="carousel">

      <!-- Indicators bullet -->
      <ol class="carousel-indicators">
        <li data-target="#carousel-example-generic" data-slide-to="0" class="active"></li>
        <li data-target="#carousel-example-generic" data-slide-to="1"></li>
      </ol>
      <!-- End Indicators bullet -->

      <!-- Wrapper for slides -->
      <div class="carousel-inner" role="listbox">

        <!-- single slide -->
        <div class="item active bg-banner">
          <div class="carousel-caption">
            <img data-wow-duration="700ms" data-wow-delay="500ms" class="wow bounceInDown animated logo"
                 src="/static/img/logo-text.png" alt="">
            <h3 data-wow-duration="1000ms" class="wow slideInLeft animated"><span class="color">短视频</span> 时代的企业赢销方案
            </h3>
            <p data-wow-duration="1000ms" class="wow slideInRight animated">做特顾 抢任务</p>

            <ul class="social-links text-center">
              <li><a href=""><i class="fa fa-twitter fa-lg"></i></a></li>
              <li><a href=""><i class="fa fa-facebook fa-lg"></i></a></li>
              <li><a href=""><i class="fa fa-google-plus fa-lg"></i></a></li>
              <li><a href=""><i class="fa fa-dribbble fa-lg"></i></a></li>
            </ul>
          </div>
        </div>
        <!-- end single slide -->

        <!-- single slide -->
        <div class="item bg-banner">
          <div class="carousel-caption">
            <img data-wow-duration="700ms" data-wow-delay="500ms" class="wow bounceInDown animated logo"
                 src="/static/img/logo-text.png" alt="">
            <h3 data-wow-duration="500ms" class="wow slideInLeft animated"><span class="color">大C</span> 经济一站式推广
            </h3>
            <p data-wow-duration="500ms" class="wow slideInRight animated">找对人 做对事</p>

            <ul class="social-links text-center">
              <li><a href=""><i class="fa fa-twitter fa-lg"></i></a></li>
              <li><a href=""><i class="fa fa-facebook fa-lg"></i></a></li>
              <li><a href=""><i class="fa fa-google-plus fa-lg"></i></a></li>
              <li><a href=""><i class="fa fa-dribbble fa-lg"></i></a></li>
            </ul>
          </div>
        </div>
        <!-- end single slide -->

      </div>
      <!-- End Wrapper for slides -->

    </div>
  </section>
</template>

<script>
export default {
  name: "Home"
}
</script>

<style scoped>
@media screen and (max-width: 1200px){
  .logo {
    width: 90%;
  }
  .bg-banner {
    background-image: url(/static/img/banner-mobile.png);
  }
}

@media screen and (min-width: 1200px){
  .bg-banner {
    background-image: url(/static/img/banner.png);
  }
}
</style>
