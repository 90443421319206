<template>
  <section id="contact" class="contact">
    <div class="container">
      <div class="row mb50">

        <div class="sec-title text-center mb50 wow fadeInDown animated" data-wow-duration="500ms">
          <h2>联系我们</h2>
          <div class="devider"><i class="fa fa-heart-o fa-lg"></i></div>
        </div>

        <!-- contact form -->
        <div class="col-lg-11 col-md-8 col-sm-7 col-xs-12 wow fadeInDown animated" data-wow-duration="500ms"
             data-wow-delay="300ms">
          <div class="contact-form">
            <h3>联系方式</h3>
            <form action="#" id="contact-form">
              <div class="input-group name-email">
                <div class="input-field">
                  <input type="text" name="name" id="name" placeholder="姓名" class="form-control">
                </div>
                <div class="input-field">
                  <input type="number" name="phone" id="phone" placeholder="电话" class="form-control">
                </div>
              </div>
              <div class="input-group">
                <textarea name="message" id="message" placeholder="留言" class="form-control"></textarea>
              </div>
              <div class="input-group text-left middle">
                <button type="submit" class="btn btn-success">提交</button>
              </div>
            </form>
          </div>
        </div>
        <!-- end contact form -->

        <!-- footer social links -->
        <div class="col-lg-1 col-md-1 col-sm-1 col-xs-12 wow fadeInRight animated" data-wow-duration="500ms"
             data-wow-delay="600ms">
          <ul class="footer-social">
            <li v-for="(item,index) in list" :key="index">
              <a :href="item.url">
                <img :src="item.img" style="width: 25px">
              </a>
            </li>
          </ul>
        </div>
        <!-- end footer social links -->
      </div>
    </div>
  </section>
</template>

<script>
const prefix = "/static/img/contact"

export default {
  name: "Contact",
  data() {
    return {
      list: [
        {img: `${prefix}/dy.png`, url: '#'},
        {img: `${prefix}/tt.png`, url: '#'},
        {img: `${prefix}/xg.png`, url: '#'},
        {img: `${prefix}/ks.png`, url: '#'},
        {img: `${prefix}/sph.png`, url: '#'},
      ]
    }
  },
}
</script>

<style scoped>
@media screen and (max-width: 1200px){
  .middle {
    text-align: center;
  }
}
</style>
