<template>
  <header id="navigation" class="navbar-fixed-top navbar">
    <div class="container">
      <div class="navbar-header">
        <!-- responsive nav button -->
        <button type="button" class="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
          <span class="sr-only">Toggle navigation</span>
          <i class="fa fa-bars fa-2x"></i>
        </button>
        <!-- /responsive nav button -->

        <!-- logo -->
        <a class="navbar-brand" href="#body">
          <h1 id="logo">
            <img src="/static/img/logo.png" alt="Brandi">
          </h1>
        </a>
        <!-- /logo -->
      </div>

      <!-- main nav -->
      <nav class="collapse navbar-collapse navbar-right" role="navigation">
        <ul id="nav" class="nav navbar-nav">
          <li><a href="#body">主页</a></li>
<!--          <li><a href="#features">特征</a></li>-->
          <li><a href="#works">服务</a></li>
          <li><a href="#team">团队</a></li>
          <li><a href="#contact">联系</a></li>
          <li><a href="#">登录</a></li>
        </ul>
      </nav>
      <!-- /main nav -->

    </div>
  </header>
</template>

<script>
export default {
  name: "Header"
}
</script>

<style scoped>

</style>
