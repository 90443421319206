<template>
  <footer id="footer" class="footer">
    <div class="container">
      <div class="row">
        <div class="col-lg-8">
          <p>
            <span class="glyphicon glyphicon-earphone" aria-hidden="true"> 15659936115 13805012462</span>
          </p>
          <p>
            <span class="glyphicon glyphicon-map-marker" aria-hidden="true"> 福州金山科技企业孵化器（园区）／6座2层</span>
          </p>

          <p>
            <span class="glyphicon glyphicon-copyright-mark" aria-hidden="true"> Copyright © 2021 tpyy. All rights reserved. <a target="_blank" href="https://beian.miit.gov.cn/">闽ICP备2022000613号-1</a></span>
          </p>

        </div>
        <div class="col-lg-2 middle">
          <img src="/static/img/contact/mp.jpeg" class="img-rounded" style="width: 120px">
        </div>
        <div class="col-lg-2 middle">
          <img src="/static/img/contact/wx.png" class="img-rounded" style="width: 120px">
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped>
@media screen and (max-width: 1200px){
  .middle {
    text-align: center;
    margin-top: 20px;
  }
}
</style>
