<template>
  <div id="app">
    <Header></Header>
    <Home></Home>
<!--    <Feature></Feature>-->
    <Works></Works>
    <Team></Team>
    <Contact></Contact>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Home from "@/components/Home";
// import Feature from "@/components/Feature";
import Works from "@/components/Works";
import Team from "@/components/Team";
import Contact from "@/components/Contact";
import Footer from "@/components/Footer";

export default {
  name: 'App',
  components: {
    Footer,
    Contact,
    Works,
    // Feature,
    Home,
    Header,
    Team,
  }
}

</script>

<style>
</style>
